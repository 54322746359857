@import url('https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.primary-hr {
  width: 90%;
  margin: 0 auto;
  height: 1px;
  border: none;
  background-color: #D5D5D5;
}

.nunito-sans-regular {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.nunito-sans-semibold {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.nunito-sans-bold {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.nunito-sans-extrabold {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.nunito-sans-black {
  font-family: "Nunito Sans", sans-serif;
  font-optical-sizing: auto;
  font-weight: 900;
  font-style: normal;
  font-variation-settings:
    "wdth" 100,
    "YTLC" 500;
}

.jost-regular {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.jost-medium {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.jost-bold {
  font-family: "Jost", sans-serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style: normal;
}

footer {
  width: 100vw;
  padding: 15px 5vw 20px;
  font-size: 14px;
  color: #666;
}

.footer-link {
  color: #000;
}

#footer-list {
  display: flex;
  justify-content: space-between;
}

#footer-text {
  margin-top: 5px;
}

#footer-hr {
  height: 2px;
  border: none;
  background-color: #ddd;
  margin-bottom: 10px;
}