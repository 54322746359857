#interviews-page {
    width: 100vw;
    padding: 18px 5%;
    display: flex;
    flex-direction: column;
    gap: 18px;
}

#interviews-page > ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
}

#interviews-page > h3 {
    font-size: 16px;
}

#interviews-page-navigation {
    display: flex; 
    gap: 15px;
}

#interviews-page-navigation > button {
    cursor: pointer;
    border: none;
    padding: 8px 18px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8.5px 2px rgb(0, 0, 0, 0.06);
}

@media only screen and (max-width: 1150px) {
    #interviews-page > ul {
        grid-template-columns: 1fr 1fr;
    } 
}

@media only screen and (max-width: 800px) {
    #interviews-page > ul {
        grid-template-columns: 1fr;
    }
    
}