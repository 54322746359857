#announcements-page {
    max-width: 100vw;
    padding: 18px 5%;
    display: grid;
    grid-template-columns: 1fr 2.3fr;
    gap: 18px;
}

#announcements-page > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#announcements-page > section > h3 {
    font-size: 16px;
}

#announcements-page > section > ul {
    max-width: 100%;
}

.announcement-date-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.announcement-date-list > h3 {
    font-size: 16px;
    color: #a46500;
}

.announcement-date-list > ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

#announcements-by-date > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
}

#announcements-today > ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

@media only screen and (max-width: 1000px) {
    #announcements-page {
        grid-template-columns: 1fr 1fr;
    }

    #announcements-by-date > ul {
        display: grid;
        grid-template-columns: 1fr;
        gap: 18px;
    }
}

@media only screen and (max-width: 850px) {
    #announcements-page {
        grid-template-columns: 1fr;
    }

}
