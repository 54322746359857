#navigation {
    width: 100vw;
    background-color: #FFF;
    display: flex;
    justify-content: space-between;
    padding: 20px 5%;
    align-items: center;
}

.nav-link {
    color: #000000;
    text-decoration: none;
}

.nav-link:hover {
    color: #e29213;
    transition-duration: 200ms;
}

.nav-link > svg {
    fill: #e29213;
    width: 20px;
}

/* .nav-link > svg:hover {
    fill: #e29213;
    transition-duration: 200ms;
} */

#nav-list {
    font-size: 18px;
    display: flex;
    gap: 36px;
    align-items: center;
}

.nav-list-item {
    display: flex;
    align-items: center;
}

#nav-mobile-heading {
    display: none;
}

#nav-desktop-logo {
    font-size: 36px;
}

#nav-mobile-logo {
    font-size: 24px;
}

@media only screen and (max-width: 1150px) {
    #navigation {
        display: block;
    }

    #nav-mobile-heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    #nav-desktop-heading {
        display: none;
    }

    #nav-list {
        font-size: 1.8em;
        height: calc(100vh - 62px);
        flex-direction: column;
        justify-content: center;
    }

    .nav-link > svg {
        width: 30px;
    }

    .hide-navbar {
        display: none;
    }
}