#about-page {
    width: 100vw;
    padding: 100px 5%;
    display: grid;
    place-items: center;
}

#about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 25px;
    width: 80%;
}

#about-image {
    display: grid;
    place-items: center;
}

#about-image > img {
    aspect-ratio: 3/2;
    width: 100%;
    object-fit: cover;
}

#about-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 18px;
}

#about-text > h2 {
    font-size: 30px;
}

@media only screen and (max-width: 1100px) {
    #about-page {
        width: 100vw;
        padding: 20px 5%;
        display: grid;
        place-items: center;
    }

    #about {
        display: grid;
        grid-template-columns: 1fr;
        gap: 25px;
        width: 80%;
    }  
}

@media only screen and (max-width: 800px) {
    #about {
        width: 100%;
    }
}