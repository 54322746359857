#articles-page {
    width: 100vw;
    padding: 18px 5%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#articles-page > ul {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 25px;
}

#articles-page > h3 {
    font-size: 16px;
}

.articles-article {
    display: grid;
    cursor: pointer;
}

.articles-cover, .articles-info {
    grid-column: 1;
    grid-row: 1;
}

.articles-info {
    padding: 0 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-image: linear-gradient(to bottom, rgb(255, 255, 255, 0.22), rgb(0, 0, 0, 0.26), rgb(0, 0, 0, 0.26), rgb(162, 162, 162, 0.26));
}

.articles-genre {
    color: #FFFFFF;
    text-transform: uppercase;
}

.articles-title {
    color: #FFFFFF;
    font-size: 32px;
    display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden; 
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
}

.articles-byline {
    color: #FFFFFF;
}

.articles-cover {
    aspect-ratio: 450 / 250;
    object-fit: cover;
    width: 100%;
}

#articles-page-navigation {
    display: flex; 
    gap: 15px;
}

#articles-page-navigation > button {
    cursor: pointer;
    border: none;
    padding: 8px 18px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 8.5px 2px rgb(0, 0, 0, 0.06);
}

@media only screen and (max-width: 1150px) and (min-width: 950px) {
    .articles-genre {
        font-size: 16px;
    }

    .articles-title {
        font-size: 25px;
    }
}

@media only screen and (max-width: 950px) {
    #articles-page > ul {
        grid-template-columns: 1fr 1fr;
    }  
}

@media only screen and (max-width: 700px) and (min-width: 600px) {
    .articles-genre {
        font-size: 16px;
    }

    .articles-title {
        font-size: 25px;
    }
}

@media only screen and (max-width: 600px) {
    #articles-page > ul {
        grid-template-columns: 1fr;
    }
    
}