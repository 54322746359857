#sports-page {
    width: 100vw;
    padding: 18px 5%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 18px;
}

#sports-page > section {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

#sports-page > section > h3 {
    font-size: 16px;
}

#games-by-date > ul {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 18px;
}

.game-date-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.game-date-list > h3 {
    font-size: 16px;
    color: #a46500;
}

.game-date-list > ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sports-game {
    box-shadow: 0 4px 8.5px 2px rgb(0, 0, 0, 0.06);
    padding: 12px 15px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.sports-game-type {
    display: flex;
    align-items: center;
    gap: 6px;
}

.sports-game-type > p {
    color: #E29213;
    font-size: 10px;
    /* flex-shrink: 1;
    flex-grow: 0; */
}

.sports-game-type > div {
    flex-shrink: 0;
    flex-grow: 1;
    background-color: #BFBFBF;
    height: 1px;
}

.sports-game-info {
    font-size: 14px;
    display: grid;
    grid-template-columns: 2fr 1fr 3fr;
    align-items: center;
}

.sports-game-right {
    font-size: 13px;
    text-align: right;
}

@media only screen and (max-width: 1250px) {
    #sports-page {
        grid-template-columns: 1fr 1fr;
    }

    #games-by-date > ul {
        display: grid;
        grid-template-columns: 1fr;
        gap: 18px;
    }
}

@media only screen and (max-width: 850px) {
    #sports-page {
        grid-template-columns: 1fr;
    }

}